const API_HOST = process.env.API_HOST || "https://shv.almende.com/api";

// Function to handle login API request
export async function login(username, password) {
  try {
    const response = await fetch(API_HOST + "/login", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ username, password }),
    });

    const result = await response.json();

    if (!response.ok) {
      throw new Error(result.message || "Login failed");
    }
    return result;
  } catch (error) {
    console.error("Error in login API:", error);
    throw error;
  }
}
// Function top handle register API request
export async function register({ username, password, firstName, lastName }) {
  try {
    const response = await fetch(API_HOST + "/register", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        username,
        password,
        firstName,
        lastName,
      }),
    });
    const result = await response.json();
    if (!response.ok) {
      throw new Error(result.error || "Registration failed");
    }
    return result;
  } catch (error) {
    throw error; 
  }
}

// Function to handle refreshToken API request
export function refreshToken(token) {
  return fetch(API_HOST + "/token", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ token }),
  });
}

// Function to handle verifyCode API request
export function verifyCode(code, tokens) {
  return fetch(API_HOST + "/verify-code", {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${tokens.accessToken}`,
    },
    method: "POST",
    body: JSON.stringify({ code }),
  });
}
