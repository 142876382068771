import React, { useEffect, useState } from 'react';
import { Container, Row } from 'react-bootstrap';
import { Form, Button, Col } from 'react-bootstrap';
import InputGroup from 'react-bootstrap/InputGroup';
import { useForm } from 'react-hook-form'
import { getUserProfile, updateProfile } from '../services';
import { Link, useNavigate } from 'react-router-dom';

export function ProfileEdit() {
    const navigate = useNavigate();
    const [profileValue, setProfileValue] = useState();
    const [auth, setAuth] = useState();
    const [error, setError] = useState();
    const [successMessage, setSuccessMessage] = useState();
    const { register, handleSubmit, setValue } = useForm();

    useEffect(() => {
        const authStr = localStorage.getItem('auth');
        if (!authStr) {
            navigate('/login')
        }
        const auth = JSON.parse(authStr);
        setAuth(auth);
        getUserProfile(auth.username, auth.accessToken).then(res => res.json()).then(values => {
            setProfileValue(values);
            const { firstName, lastName, age, sex, pharmacy, gp, listOfMedicine, address } = values;
            setValue('firstName', firstName);
            setValue('lastName', lastName);
            setValue('sex', sex);
            setValue('age', age);
            setValue('gp', gp);
            setValue('pharmacy', pharmacy);
            setValue('listOfMedicine', listOfMedicine);
            setValue('address', address);
        }).catch(e => {
            console.log(e);
            setError('Error in fetching user profile.')
        });
    }, []);


    const submitForm = (data) => {
        console.log(data)
        updateProfile(auth.username, auth.accessToken, data).then(res => res.json())
            .then(res => setSuccessMessage(res.message))
            .catch(res => setError(res.message));
    }
  return (
        <>
            {error && <p className='text-danger'>{error}</p>}
            <Row>
                <Col>
                    <Form onSubmit={handleSubmit(submitForm)}>
                        <InputGroup className="mb-3">
                            <InputGroup.Text>First and last name</InputGroup.Text>
                            <Form.Control defaultValue={profileValue?.firstName} {...register('firstName')} aria-label="First name" />
                            <Form.Control defaultValue={profileValue?.lastName} {...register('lastName')} aria-label="Last name" />
                        </InputGroup>

                        <Form.Select defaultValue={profileValue?.sex} {...register('sex')}>
                            <option>Sex</option>
                            <option value="male">Male</option>
                            <option value="female">Female</option>
                            <option value="other">Other</option>
                        </Form.Select>


                        <Form.Group className="mb-3">
                            <Form.Label>Age</Form.Label>
                            <Form.Control {...register('age')} min={1} max={99} type="number" placeholder="Enter your age..." />
                        </Form.Group>

                        <Form.Group className="mb-3">
                            <Form.Label>Address</Form.Label>
                            <Form.Control {...register('address')} type="text" placeholder="Enter your address..." />
                        </Form.Group>


                        <Form.Group className="mb-3">
                            <Form.Label>GP</Form.Label>
                            <Form.Control {...register('gp')} type="text" placeholder="Enter your GP name..." />
                        </Form.Group>

                        <Form.Group className="mb-3">
                            <Form.Label>Pharmacy</Form.Label>
                            <Form.Control {...register('pharmacy')} type="text" placeholder="Enter your pharmacy name..." />
                        </Form.Group>

                        <Form.Group className="mb-3">
                            <Form.Label>List of Medicine</Form.Label>
                            <Form.Control {...register('listOfMedicine')} type="text" placeholder="Enter the link to the list of medicine google sheet here..." />
                        </Form.Group>
                        {successMessage && <p className='text-success'>{successMessage}</p>}
                        <Button variant="primary" type="submit" marginTop="50">
                            Submit
                        </Button>
                    </Form>
                </Col>
            </Row>
        </>
    )
}
