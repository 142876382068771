import { useState } from "react";
import { ProfileEdit } from "./ProfileEdit";
import { ProfileView } from "./ProfileView";
import { Button, Container, Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";

export function Profile() {
  const [editMode, setEditMode] = useState(false);

  return (
    <Container>
      <Row className="mb-5 mt-3">
        <Link to="/dashboard"> {"<"} Back to dashboard</Link>
      </Row>
      <Row>
        <Col> {editMode ? <ProfileEdit /> : <ProfileView />}</Col>
        <Col>
          <Button variant="link" onClick={() => setEditMode(!editMode)}>
            {editMode ? "Cancel Edit" : "Edit Profile"}
          </Button>
        </Col>
      </Row>
    </Container>
  );
}
