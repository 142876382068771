import { useEffect } from "react";
import { Container } from "react-bootstrap";
import { Outlet, useNavigate } from "react-router-dom";
import { refreshToken } from "../services/authService";

const ONE_MINUTE = 60 * 1000;

export function Layout() {
  const navigate = useNavigate();

  useEffect(() => {
    const authStr = localStorage.getItem("auth");
    if (!authStr) {
      navigate("/login");
    } else {
      const auth = JSON.parse(authStr);
      setInterval(() => {
        refreshToken(auth.refreshToken)
          .then((res) => res.json())
          .then((res) => {
            auth.accessToken = res.accessToken;
            localStorage.setItem("auth", JSON.stringify(auth));
          });
      }, 8 * ONE_MINUTE);
    }

  }, []);
  return (
    <Container>
      <Outlet />
    </Container>
  );
}
