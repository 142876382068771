import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { getUserProfile } from "../services";
import { useNavigate } from "react-router-dom";

export function ProfileView() {
  const [userInfo, setUserInfo] = useState();
  const [error, setError] = useState();
  const navigate = useNavigate();

  useEffect(() => {
    const authStr = localStorage.getItem("auth");
    if (!authStr) {
      navigate("/login");
    }

    const auth = JSON.parse(authStr);
    
    // Fetch the user profile data 
    getUserProfile(auth.username, auth.accessToken)
      .then((res) => res.json())
      .then(setUserInfo)
      .catch((e) => {
        console.log(e);
        setError("Error in fetching user info.");
      });
  }, []);

  if (!userInfo) {
    return <h2 className="text-center">Loading...</h2>;
  }

  return (
    <Container>
      {/** Personal Info */}
      {error && <p className="text-danger">{error}</p>}
      <h2>Personal Info</h2>
      <Row>
        <Col>
          <dt>First Name</dt>
          <dd>{userInfo.firstName}</dd>
          <dt>Last Name</dt>
          <dd>{userInfo.lastName}</dd>
          <dt>Username</dt>
          <dd>{userInfo.username}</dd>
        </Col>
        <Col>
          <dt>Age</dt>
          <dd>{userInfo.age}</dd>
          <dt>Sex</dt>
          <dd>{userInfo.sex}</dd>
          <dt>Address</dt>
          <dd>{userInfo.address}</dd>
        </Col>
      </Row>
      {/** GP Info*/}
      <h2>General Practitioner</h2>
      <Row>
        <Col>
          <dt>Name</dt>
          <dd>{userInfo.gp}</dd>
          <dt>Address</dt>
          <dd>{userInfo.gpAddress}</dd>
        </Col>
        <Col></Col>
      </Row>
      {/** Medical History */}
      <h2>Medical History</h2>
      <Row>
        <Col>
          <dt>List of Medicine</dt>
          <dd>
            {!userInfo.listOfMedicine && "No link is provided."}
            {userInfo.listOfMedicine && (
              <a href={userInfo.listOfMedicine}>Google sheet link</a>
            )}
          </dd>
        </Col>
      </Row>
    </Container>
  );
}
