import React, { useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";

export default function Home(){
    const navigate = useNavigate();

    useEffect(() => {
        const auth = localStorage.getItem('auth');
        if(!auth) {
            navigate('/login')
        } else {
            navigate('/dashboard')
        }
    }, [])
    return(
        <div> 
            <Link to='login'> Login </Link>
            <Link to='register'> Register</Link>
        </div>
    )
}