import { useState } from "react";
import { Form, Button } from "react-bootstrap";
import { Col, Container, Row } from "react-bootstrap";
import Image from "react-bootstrap/Image";
import { Link, useNavigate } from "react-router-dom";
import { login } from "../services/authService";

export default function Login() {

  const [username, setUsername] = useState();
  const [password, setPassword] = useState();
  const [error, setError] = useState();
  const navigate = useNavigate()
  const handleSubmit = async (e) => {
    e.preventDefault();

      try {
        const result = await login(username, password); 
  
        // Save token to local storage
        localStorage.setItem('auth', JSON.stringify(result));
        // Navigate to the next step
        navigate('/verify-email');
      } catch (err) {
        // Display error if login fails
        setError(err.message);
      }
    };
  return (
    <Container style={{ marginTop: '10%' }}>
      <Row className="justify-content-md-center">
        <Col md={5}>
          <h1>Login</h1>
        </Col>
      </Row>

      <Row className="justify-content-md-center">
        <Col md={5}>
          <Row>
            <Col>
              <Image style={{ width: 300, marginBottom: '70px' }} src='/logo.jpeg' />
            </Col>
          </Row>
          <Row>
            <Col className="text-danger">
              {error}
            </Col>
          </Row>
          <Form onSubmit={handleSubmit}>
            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Label>Email address</Form.Label>
              <Form.Control onChange={e => setUsername(e.target.value)} type="email" placeholder="Enter email" />
              <Form.Text className="text-muted">
                We'll never share your email with anyone else.
              </Form.Text>
            </Form.Group>

            <Form.Group className="mb-3" controlId="formBasicPassword">
              <Form.Label>Password</Form.Label>
              <Form.Control onChange={e => setPassword(e.target.value)} type="password" placeholder="Password" />
            </Form.Group>

            <Form.Group className="mb-3" controlId="formBasicCheckbox">
              <Form.Check type="checkbox" label="Remember me" />
            </Form.Group>
            <Col>
            <Link to="/register">Create a new account</Link>
            </Col>
            <Button variant="primary" type="submit">
              Submit
            </Button>
          </Form>
        </Col>
      </Row>

    </Container>
  );
}
