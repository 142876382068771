import React, { useEffect, useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import { Container, Form, Row, Col, Button } from "react-bootstrap";
import dayjs from "dayjs";
import { getMpcResult, getDailySteps, getSyncTodaySteps } from "../services";
import Image from "react-bootstrap/Image";
import PersonalBarChart from "../components/personalBarChart";

const apiKey = "d8f3C9Ab-3eF7-4dB8-A12e-9cB09A7eC1D4";
export default function Dashboard() {
  const currentDate = dayjs();

  const [passPhrase, setPassPhrase] = useState();
  const [error, setError] = useState();
  const [passphraseFormValue, setPassPhraseFormValue] = useState();
  const [mpcAverageSteps, setMpcAverageSteps] = useState();
  const [dailySteps, setDailySteps] = useState();
  const [dailyWeeklyAverage, setDailyWeeklyAverage] = useState();
  const [mpcWeeklyAverage, setMpcWeeklyAverage] = useState();
  const [startDate, setStartDate] = useState(
    currentDate.add(-7, "days").format("YYYY-MM-DD")
  );
  const [mpcData, setMpcData] = useState();
  const [endDate, setEndDate] = useState(currentDate.format("YYYY-MM-DD"));
  const [refreshData, setRefreshData] = useState(0);
  const [isSyncing, setIsSyncing] = useState(false);
  const navigate = useNavigate();
  const auth = JSON.parse(localStorage.getItem("auth"));

  useEffect(() => {
    const pp = localStorage.getItem("passPhrase");
    if (pp && pp !== "undefined") {
      setPassPhrase(pp);
    }
  }, [passPhrase]);

  const handleSubmit = (e) => {
    e.preventDefault();
    setPassPhrase(passphraseFormValue);
  };

  useEffect(() => {
    if (!auth) {
      navigate("/login");
    }
  }, [auth]);

  useEffect(() => {
    console.log("useEffect:", { passPhrase, startDate, endDate, refreshData });

    if (!passPhrase) return;

    localStorage.setItem("passPhrase", passPhrase);

    // Fetch MPC result
    getMpcResult(passPhrase, apiKey, startDate, endDate)
      .then((data) => {
        if (!data) {
          console.error("No data received for MPC Result.");
          setError("Failed to load MPC Result data.");
        } else {
          console.log("MPC Result:", data);
          const dates = data.map((d) =>
            dayjs(d.timestamp).format("YYYY-MM-DD")
          );
          setMpcAverageSteps({
            labels: dates,
            values: data.map((d) => d.average_steps),
          });
        }
      })
      .catch((error) => {
        console.error("Error in MPC Result fetch in useEffect:", error);
        setError("Error fetching MPC Result data.");
      });

    // Fetch and process daily steps data
    getDailySteps(passPhrase, apiKey, startDate, endDate)
      .then((data) => {
        if (!data) {
          console.error("No data received for Daily Steps.");
          setError("Failed to load Daily Steps data.");
        } else {
          console.log("Daily Steps Data:", data);
          const dates = data.map((d) =>
            dayjs(d.timestamp).format("YYYY-MM-DD")
          );
          setDailySteps({ labels: dates, values: data.map((d) => d.step) });
        }
      })
      .catch((e) => {
        console.error("Error in Daily Steps fetch in useEffect:", e);
        setError("Error in loading daily steps data.");
      });
  }, [passPhrase, startDate, endDate, refreshData]); 

  // sync today step
  const syncTodaySteps = async () => {
    setIsSyncing(true);
    try {
      const sync = await getSyncTodaySteps(passPhrase, apiKey);
      setRefreshData((prev) => prev + 1);
    } catch (error) {
      console.error("Error syncing data from multiple APIs:", error);
      setError("Error syncing today's steps.");
    } finally {
      setIsSyncing(false);
    }
  };

  return (
    <Container>
      <Row>
        <Row>
          <Col>
            <Image
              style={{ width: 300, marginBottom: "70px" }}
              src="/logo.jpeg"
            />
          </Col>
          <Col sm={3}>
            Logged in <Link to="./profile">{auth.firstName}</Link>{" "}
            <Button
              variant="link"
              onClick={() => {
                localStorage.removeItem("auth");
                localStorage.removeItem("passPhrase");
                navigate("/login");
              }}
            >
              Logout
            </Button>
          </Col>
        </Row>{" "}
        {!passPhrase && (
          <Col>
            <Form onSubmit={handleSubmit}>
              <Form.Group className="mb-3" controlId="formBasicPassword">
                <Form.Label>Pass Phrase</Form.Label>
                <Form.Control
                  onChange={(e) => setPassPhraseFormValue(e.target.value)}
                  type="password"
                  placeholder="Enter Pass Phrase"
                />
              </Form.Group>
            </Form>
          </Col>
        )}
      </Row>

      {error && <p className="text-danger">{error}</p>}
      {passPhrase && (!mpcAverageSteps || !dailySteps) && (
        <Row>
          <Col className="text-center mt-5">
            <h2>Loading...</h2>
          </Col>
        </Row>
      )}

      {mpcAverageSteps && dailySteps && (
        <Row className="mb-6">
          <Col>
            <Button
              onClick={syncTodaySteps}
              disabled={isSyncing}
              style={{
                backgroundColor: "#FFA500",
                borderColor: "#FFA500",
                color: "#fff",
              }}
            >
              {isSyncing ? "Syncing Steps..." : "Update Today's Steps"}
            </Button>

            <PersonalBarChart
              data={{
                mpcAverageSteps,
                dailySteps,
                mpcWeeklyAverage,
                dailyWeeklyAverage,
              }}
            />
          </Col>
        </Row>
      )}
    </Container>
  );
}
