import { useNavigate } from "react-router-dom";
import { useState } from "react";
import { Form, Button } from "react-bootstrap";
import { Col, Container, Row } from "react-bootstrap";
import Image from "react-bootstrap/Image";

import { verifyCode } from "../services/authService";

export default function VerifyEmail() {

    const navigate = useNavigate()
    const tokens = JSON.parse(localStorage.getItem('auth'));
    const [code, setCode] = useState();
    const [error, setError] = useState();

    if (!tokens) {
        navigate('/login')
        return null;
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        if(!code) {
            setError('Enter the code!');
            return;
        }
        const response = await verifyCode(code,tokens);

        if (!response.ok) {
            if (response.status === 400) {
                const result = await response.json();

                setError(result.message);
            } else {
                setError(`Server error ${response.status}`);
                if (response.status === 401) {
                    navigate('/login');
                }
            }
            return;
        }

        navigate('/dashboard')
    }
    return <Container style={{ marginTop: '10%' }}>
        <Row className="justify-content-md-center">
            <Col md={5}>
                <h1>Login</h1>
            </Col>
        </Row>

        <Row className="justify-content-md-center">
            <Col md={5}>
                <Row>
                    <Col>
                        <Image style={{ width: 300, marginBottom: '70px' }} src='/logo.jpeg' />
                    </Col>
                </Row>
                <Row>
                    <Col className="text-danger">
                        {error}
                    </Col>
                </Row>
                <Form onSubmit={handleSubmit}>
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                        <Form.Label>Code you received by email</Form.Label>
                        <Form.Control onChange={e => setCode(e.target.value)} type="text" placeholder="Enter code" />
                    </Form.Group>

                    <Button variant="primary" type="submit">
                        Submit
                    </Button>
                </Form>
            </Col>
        </Row>

    </Container>
}