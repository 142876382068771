import React from "react";
import { Bar } from "react-chartjs-2";
import { Col, Row } from "react-bootstrap";

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

export default function PersonalBarChart({ data = {} }) {
  const { mpcAverageSteps = [{}], dailySteps = [{}] } = data;
  const numDays = 7;
  const currentDate = new Date();

  // Create date range for the past 7 days including today
  const pastDateRange = Array.from({ length: numDays }, (_, i) => {
    const date = new Date(currentDate);
    date.setDate(currentDate.getDate() - (numDays - i - 1)); // Adjust to get past dates
    return date.toISOString().split("T")[0]; // Format as YYYY-MM-DD
  });

  // Align dailySteps data to pastDateRange
  const dailyDataMap = new Map(
    dailySteps.labels.map((label, index) => [label, dailySteps.values[index]])
  );
  const alignedDailyValues = pastDateRange.map(
    (date) => dailyDataMap.get(date) || 0
  );

  // Align MPC data to pastDateRange (MPC data should start from today)
  const mpcDataMap = new Map(
    mpcAverageSteps.labels.map((label, index) => [
      label,
      mpcAverageSteps.values[index],
    ])
  );
  const alignedMpcValues = pastDateRange.map(
    (date) => mpcDataMap.get(date) || 0
  );

  // Calculate weekly averages
  const calculateWeeklyAverage = (values) =>
    values.reduce((sum, val) => sum + val, 0) / values.length;
  const dailyWeeklyAverage = calculateWeeklyAverage(alignedDailyValues);
  const mpcWeeklyAverage = calculateWeeklyAverage(alignedMpcValues);

  // Create datasets for weekly averages

  const weeklyAverageData = new Array(numDays).fill(dailyWeeklyAverage);
  const mpcWeeklyAverageData = new Array(numDays).fill(mpcWeeklyAverage);

  const personalChart = {
    labels: pastDateRange, // Use pastDateRange for X-axis labels
    datasets: [
      {
        label: "Daily Steps",
        data: alignedDailyValues, // Data for the past 7 days
        backgroundColor: "rgba(255, 165, 0, 1)",
      },
      {
        label: "MPC Step Result",
        data: alignedMpcValues, // Data for the past 7 days
        backgroundColor: "rgba(0, 128, 0, 1)",
      },
      {
        label: "Weekly Average Daily Steps",
        type: "line",
        data: weeklyAverageData,
        borderColor: "rgba(75, 192, 192, 1)",
        borderWidth: 2,
        fill: false,
        tension: 0.1,
      },
      {
        label: "Weekly Average MPC Steps",
        type: "line",
        data: mpcWeeklyAverageData,
        borderColor: "rgba(153, 102, 255, 1)",
        borderWidth: 2,
        fill: false,
        tension: 0.1,
      },
    ],
  };

  const options = {
    scales: {
      x: {
        title: {
          display: true,
          text: "Days",
          font: {
            size: 16,
            weight: "bold",
          },
        },
        ticks: {
          autoSkip: false,
          font: {
            size: 16,
            weight: "bold",
          },
        },
      },
      y: {
        beginAtZero: true,
        title: {
          display: true,
          text: "Steps",
          font: {
            size: 16,
            weight: "bold",
          },
        },
        ticks: {
          font: {
            size: 12,
            weight: "bold",
          },
        },
      },
    },

    responsive: true,
    maintainAspectRatio: false,
    
  };

  return (
    <Row>
      <Col>
        {/* Set a large width and height via inline styles */}
        <div style={{ width: "1200px", height: "500px", margin: "auto" }}>
          <Bar data={personalChart} options={options} />
        </div>
      </Col>
    </Row>
  );
}
