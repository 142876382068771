const MPC_HOST = process.env.MPC_HOST || "https://shv.almende.com/mpc"; // Base URL for all API calls
const API_HOST = process.env.API_HOST || "https://shv.almende.com/api";

// Mpc result API call
export function getMpcResult(passphrase, apiKey, start_date, end_date) {
  return (
    fetch(MPC_HOST + "/api/mpc_results_by_date", {
      headers: {
        "Content-Type": "application/json",
        "X-API-Key": apiKey,
      },
      method: "POST",
      body: JSON.stringify({
        passphrase,
        start_date,
        end_date,
      }),
    })

      .then((response) => {
        console.log("MPC API Response Received", response.status);
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        return response.json(); 
      })
      .then((data) => {
        console.log("MPC API Data:", data);
        return data;
      })
      .catch((error) => {
        console.error("Error fetching MPC data:", error);
      })
  );
}

// Daily steps API call
export function getDailySteps(passphrase, apiKey, start_date, end_date) {
  return (
    fetch(MPC_HOST + "/api/daily_steps_by_date", {
      headers: {
        "Content-Type": "application/json",
        "X-API-Key": apiKey,
      },
      method: "POST",
      body: JSON.stringify({
        passphrase,
        start_date,
        end_date,
      }),
    })
      .then((response) => {
        console.log("Daily Steps API Response Received", response.status); 
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        return response.json();
      })
      .then((data) => {
        console.log("Daily Steps API Data:", data);
        return data;
      })
      .catch((error) => {
        console.error("Error fetching daily steps data:", error);
      })
  );
}

// Sync today's steps API call

export async function getSyncTodaySteps(passphrase, apiKey) {
  const [syncResponse, mpcResponse] = await Promise.all([
    fetch(MPC_HOST + "/api/sync_today_steps", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "x-api-key": apiKey,
      },
      body: JSON.stringify({ passphrase, apiKey }),
    }),

    fetch(MPC_HOST + "/api/run_mpc_on_steps_data", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "x-api-key": apiKey,
      },
      body: JSON.stringify({ passphrase }),
    }),
  ]);

  return { syncResponse, mpcResponse };
}

// User profile API call
export function getUserProfile(username, token) {
  return fetch(`${API_HOST}/profile/${username}`, {
    headers: {
      Authorization: "Bearer " + token,
    },
  });
}
// Update profile API call
export function updateProfile(username, token, fields) {
  return fetch(API_HOST + "/profile/" + username, {
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    },
    method: "PUT",
    body: JSON.stringify(fields),
  });
}
