import { useState } from "react";
import { Form, Button } from "react-bootstrap";
import { Col, Container, Row } from "react-bootstrap";
import Image from "react-bootstrap/Image";
import { useNavigate } from "react-router-dom";
import { register } from "../services/authService";

export default function Register() {
  const [username, setUsername] = useState();
  const [password, setPassword] = useState();
  const [repeatPassword, setRepeatPassword] = useState();
  const [firstName, setFirstName] = useState();
  const [lastName, setLastName] = useState();
  const navigate = useNavigate();
  const [error, setError] = useState();

  const handleSubmit = async (e) => {
    e.preventDefault();

  if (!username || !password || !firstName || !lastName) {
    setError("All fields are required.");
    return;
  }

    if (password !== repeatPassword) {
      setError("Passwords do not match.");
      return;
    }

    try {
      const result = await register({
        username,
        password,
        firstName,
        lastName,
      });

      if (result.status === "ok") {
        navigate("/login");
      } else {
        setError(result.error || "Registration failed. Please try again.");
      }
    } catch (err) {
      setError(err.message || "An unexpected error occurred.");
    }
  }; 

  return (
    <Container style={{ marginTop: "10%" }}>
      <Row className="justify-content-md-center">
        <Col md={5}>
          <h1>Register</h1>
        </Col>
      </Row>

      <Row className="justify-content-md-center">
        <Col md={5}>
          <Row>
            <Col>
              <Image
                style={{ width: 300, marginBottom: "70px" }}
                src="/logo.jpeg"
              />
            </Col>
          </Row>

          <Form onSubmit={handleSubmit}>
          <p className="text-danger">{error}</p>
            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Label>Email address</Form.Label>
              <Form.Control
                onChange={(e) => setUsername(e.target.value)}
                type="email"
                placeholder="Enter email"
              />
              <Form.Text className="text-muted">
                We'll never share your email with anyone else.
              </Form.Text>
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>First Name</Form.Label>
              <Form.Control
                onChange={(e) => setFirstName(e.target.value)}
                type="text"
                placeholder="Enter your first name..."
              />
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>Last Name</Form.Label>
              <Form.Control
                onChange={(e) => setLastName(e.target.value)}
                type="text"
                placeholder="Enter your last name..."
              />
            </Form.Group>

            <Form.Group className="mb-3" controlId="formBasicPassword">
              <Form.Label>Password</Form.Label>
              <Form.Control
                onChange={(e) => setPassword(e.target.value)}
                type="password"
                placeholder="Password"
              />
            </Form.Group>

            <Form.Group className="mb-3" controlId="formBasicUserName">
              <Form.Label>Repeat Password</Form.Label>
              <Form.Control
                onChange={(e) => setRepeatPassword(e.target.value)}
                type="password"
                placeholder="Repeat Password"
              />
            </Form.Group>

            <Form.Group className="mb-3" controlId="formBasicCheckbox">
              <Form.Check type="checkbox" label="Remember me" />
            </Form.Group>
            <Button variant="primary" type="submit">
              Submit
            </Button>
          </Form>
        </Col>
      </Row>
    </Container>
  );
}
